import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AppService } from 'src/app/app.service';
import { PopupApi } from '../../apis/popup.api';
import { PopupBanner } from '../../interfaces/popup.interface';
import { LocalStorageService } from '../../service/localstorage.service';

@UntilDestroy()
@Component({
  selector: 'banner-popup',
  templateUrl: './banner-popup.component.html',
  styleUrls: ['./banner-popup.component.scss'],
})
export class BannerPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bannerList: PopupBanner[] },
    private dialogRef: MatDialogRef<BannerPopupComponent>,
    private localStorageService: LocalStorageService,
    private appService: AppService,
    private router: Router,
    private popupApi: PopupApi
  ) {}

  @Input() bannerList: PopupBanner[] = [];

  domain = window.location.origin;

  isH5!: boolean;
  isLoading: boolean = false;
  isShowAnimation: boolean = false;
  isLogin: boolean = false;
  isHide: boolean = false;
  curBannerIdx: number = 0;
  curBanner: PopupBanner = {
    id: 0,
    title: '',
    content: '',
    image: '',
    url: '',
  };

  ngOnInit() {
    if (!this.data) return;

    this.bannerList = this.data.bannerList;

    this.isLogin = Boolean(this.localStorageService.loginToken);
    this.isHide = this.localStorageService.getValueByJsonParse('isHideBannerPopupBE') || false;

    if (this.isLogin) {
      this.isHide = this.localStorageService.getValueByJsonParse('isHideBannerPopupAF') || false;
    }

    if (this.bannerList.length > 0) {
      this.curBanner = this.bannerList[this.curBannerIdx];
    }
  }

  goToLink() {
    if (this.curBanner.url) {
      this.router.navigateByUrl(this.curBanner.url);
      this.dialogRef.close();
    }
  }

  onShowChange() {
    this.isHide = !this.isHide;

    let curHideKey = 'isHideBannerPopupBE';
    let curHideTimeKey = 'isHideBannerPopupBETime';

    if (this.isLogin) {
      curHideKey = 'isHideBannerPopupAF';
      curHideTimeKey = 'isHideBannerPopupAFTime';
    }

    if (this.isHide) {
      this.localStorageService.setValueByJsonStringify(curHideKey, true);
      this.localStorageService.setValueByJsonStringify(curHideTimeKey, new Date());
    } else {
      this.localStorageService.setValueByJsonStringify(curHideKey, false);
      this.localStorageService.setValueByJsonStringify(curHideTimeKey, '');
    }
  }

  onClose() {
    this.isShowAnimation = true;
    this.curBannerIdx += 1;

    if (this.curBannerIdx < this.bannerList.length) {
      // delay 400ms for data change
      setTimeout(() => {
        this.curBanner = this.bannerList[this.curBannerIdx];
      }, 400);

      // delay 600ms for animation
      setTimeout(() => {
        this.isShowAnimation = false;
      }, 600);
    } else {
      this.dialogRef.close();
    }
  }

  get langCode() {
    return this.appService.languageCode;
  }
}
