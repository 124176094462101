import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PopupBanner } from 'src/app/shared/interfaces/popup.interface';
import { ResponseData } from 'src/app/shared/interfaces/response.interface';
import { environment } from 'src/environments/environment';
import { BaseApi } from './base.api';

@Injectable({
  providedIn: 'root',
})
export class PopupApi extends BaseApi {
  /**
   * Get Banner Popup List
   *
   * @param clientType 客户端类型(Web、APP)
   * @returns
   */
  getPopupList(): Observable<PopupBanner[]> {
    const url = `${environment.apiUrl}/v1/resource/popup/getpopuplist`;
    return this.get<ResponseData<PopupBanner[]>>(url, { clientType: 'Web' }).pipe(map(v => v?.data || []));
  }
}
